import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import minusimg from "../assets/images/minus.svg"
import plusimg from "../assets/images/plus.svg"

const FAQ = ({ accordionData }) => {
    const [openIndex, setOpenIndex] = useState(null);
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className='homepage' >
            <section className='faq' id='faq'>
                <div className='faq-content' >
                    <div className='t-head' >
                        <h2 className='h2-text-1'>Frequently Asked Questions</h2>
                    </div>
                    <div className='faq-accordion-section'>
                        <div className="accordion" id="accordionExample">
                            {accordionData.map((item, index) => (
                                <div className="accordion-item" key={item.id}>
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse${item.id}`}
                                        aria-expanded={openIndex === index ? 'true' : 'false'}
                                        aria-controls={`collapse${item.id}`}
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        <h3 className="h3-text-4">
                                            <span>{`0${item.id}`}</span>
                                            {item.title}
                                        </h3>
                                        <LazyLoadImage
                                            src={openIndex === index ? minusimg : plusimg} // Change icon based on open state
                                            alt="faq"
                                            className={`faq-btn-img ${openIndex === index ? 'rotate' : ''}`}
                                        />
                                    </button>
                                    <div
                                        id={`collapse${item.id}`}
                                        className={`accordion-collapse collapse ${openIndex === index ? 'show' : ''}`}
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p className="accordion-p">{item.content}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FAQ
