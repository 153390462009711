import { makeApiRequest } from "./axiosService";

export const saveContactForm = async (bodyData) => {
    try {
        let params = {
            url: `Crewsor/v1/site/saveContact`,
            method: "POST",
            data: bodyData
        }
    
        let response = await makeApiRequest(params);
        return {
            status: response.status,
            message: response.message
        }
    }
    catch (error) {
        console.log('buytoken error', error);
        return {
            status: false,
            message: "Error on server"
        }
    }
}
export const subscribeEmail = async (bodyData) => {
    try {
        let params = {
            url: `Crewsor/v1/site/subscribe`,
            method: "POST",
            data: bodyData
        }
        console.log('bodyData', bodyData)
        let response = await makeApiRequest(params);
        console.log('response :>> ', response);
        return {
            status: response.status,
            message: response.message
        }
    }
    catch (error) {
        console.log('buytoken error', error);
        return {
            status: false,
            message: "Error on server"
        }
    }
}