import React, { useState } from 'react';
import logo from "../../assets/images/footerlogo.svg";
import { Link, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import linkedin from "../../assets/images/fsl1.svg";
import instagram from "../../assets/images/fsl2.svg"
import twitter from "../../assets/images/fsl3.svg";
import tiktok from "../../assets/images/fsl4.svg"
import locationicon from "../../assets/images/footLocation.svg";
import mail from "../../assets/images/footerMail.svg";
import call from "../../assets/images/footerCall.svg";
import footbg from "../../assets/images/footbg.svg"
import { subscribeEmail } from '../../authiapis/backendcall';



const Footer = () => {
    const location = useLocation();


    const [email, setEmail] = useState("")
    const [error, setError] = useState({});


    const validate = () => {
        const newErrors = {};
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!emailPattern.test(email)) {
            newErrors.email = 'Invalid email format';
        }
        return newErrors;
    };


    const handleSubscribe = async () => {
        try {
            const validationErrors = validate();
            if (Object.keys(validationErrors).length != 0) {
                setError(validationErrors)
            } else {
                setError('')
                let data = { email: email }
                console.log('data :>> ', data);
                const response = await subscribeEmail(data);
                console.log('response :>> ', response);
            }
        } catch (error) {
            console.log(error)

        }
    }


    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            const sectionTop = section.getBoundingClientRect().top + window.pageYOffset;
            const scrollOptions = {
                top: sectionTop,
                behavior: 'smooth'
            };

            window.scrollTo(scrollOptions);

            // Add a scroll listener to stop scrolling when the section is in view
            const handleScroll = () => {
                const currentScroll = window.pageYOffset;
                const sectionOffset = sectionTop;

                // Check if the current scroll position is close enough to the target section
                if (Math.abs(currentScroll - sectionOffset) < 5) {
                    window.removeEventListener('scroll', handleScroll);
                    window.scrollTo(0, sectionOffset); // Ensure the scroll is exactly at the section
                }
            };

            window.addEventListener('scroll', handleScroll);
        }
    };








    const hideLinksPaths = ['/terms-and-condition', '/pdpa-policy', '/media-policy'];
    const shouldHideLinks = hideLinksPaths.includes(location.pathname);
    const shouldShowLinks = hideLinksPaths.includes(location.pathname);





    return (
        <div className='footer'>
            <div className='footer-content'>
                <LazyLoadImage src={footbg} alt='footerimg' className='foot-bg-img img-fluid' />
                <div className='foot-head'>
                    <div className='row g-3'>
                        <div className='col-lg-3 col-md-12 col-12'>
                            <Link to="/" reloadDocument>
                                <LazyLoadImage src={logo} alt='logo' className='f-logo' />
                            </Link>
                        </div>
                        <div className='col-lg-9 col-md-12 col-12 f-ul-div'>
                            <ul className='foot-ul '>
                                <li>
                                    <Link to="#about" className='f-nav-link' onClick={() => scrollToSection('about')}>
                                        About
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#how-it-works" className='f-nav-link' onClick={() => scrollToSection('contact')}>How it works</Link>
                                </li>
                                <li>
                                    <Link to="#benefits" className='f-nav-link' onClick={() => scrollToSection('benefits')}>Benefits</Link>
                                </li>
                                <li>
                                    <Link to="#download" className='f-nav-link' onClick={() => scrollToSection('download')}>Download</Link>
                                </li>
                                <li>
                                    <Link to="#faqs" className='f-nav-link' onClick={() => scrollToSection('faq')}>FAQs</Link>
                                </li>

                                <li>
                                    <Link to="#contact" className='f-nav-link' onClick={() => scrollToSection('contact')}>Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='row g-4 mt-3 foot-center'>
                    <div className='col-lg-5 col-md-5 col-12'>
                        <div className='f-left'>
                            <div className='div-content-bg'>
                                <p>Contact Us</p>
                            </div>
                            <p className='email-p'><LazyLoadImage src={mail} alt='mail' className='mail' /><a href='mailto: info@crewsor.com' className='a-mail'> info@crewsor.com</a></p>
                            <p className='phone-p'><LazyLoadImage src={call} alt='call' className='call' /> +65 555-567-8901</p>
                            <p className='address-p'>
                                <LazyLoadImage src={locationicon} alt='location' className='location' /> 35 Kallang Pudding Road, <br />
                                #06-12 Tong Lee Building Block A <br className='t-break' />
                                Singapore 349314
                            </p>
                            <div className='social-link'>
                                <Link to="">
                                    <LazyLoadImage src={linkedin} alt='linkedin' className='linkedin' />
                                </Link>
                                <Link to="">
                                    <LazyLoadImage src={instagram} alt='facebook' className='facebook' />
                                </Link>
                                <Link to="">
                                    <LazyLoadImage src={twitter} alt='twitter' className='twitter' />
                                </Link>
                                <Link to="">
                                    <LazyLoadImage src={tiktok} alt='twitter' className='twitter' />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7 col-md-7 col-12'>
                        <div className='foot-form-div'>
                            <p className='f-form-label'>Subscribe to our newsletter for the latest insights and updates</p>
                            <div className='form-row-foot'>
                                <div className='row g-3 w-100 '>
                                    <div className='col-lg-6 col-md-12 col-12 input-col-foot'>
                                        <input type="text"
                                            class="form-control foot-f-control"
                                            placeholder="Email"
                                            name='email'
                                            value={email}
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {
                                            error.email && <p className='error-p'>{error.email}</p>
                                        }


                                    </div>
                                    <div className='col-lg-6 col-md-12 col-12'>
                                        <button className='btn-subscribe' onClick={handleSubscribe}>
                                            Subscribe
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='vr-line' />
                <div className='foot-last'>
                    <div className='copy-right'>
                        <div className='col-lg-6 col-md-8 col-12 order-lg-1 order-md-1 order-2'>
                            <p className='copy-p' id='copy-p'>©2024 Crewsor Pte.Ltd, All Right Reserved.</p>
                            <p className='p-uen'>(UEN:202434812N)</p>
                        </div>
                        <div className='col-lg-6 col-md-4 col-12 order-lg-2 order-md-2 order-1 '>
                            <div className='l-foot-bottom'>
                                <ul className='fb-ul'>
                                    <li>
                                        <Link to="/pdpa-policy" reloadDocument className='fb-nav-link' >
                                            PDPA Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/media-policy" reloadDocument className='fb-nav-link'>
                                            Media Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-and-conditions" reloadDocument className='fb-nav-link'>
                                            Terms and Conditions
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
