import React, { Fragment } from 'react'
import Meta from '../components/Meta'

const MediaPolicy = () => {
    return (
        <Fragment>
            <Meta
                title="Media Usage Policy | Crewsor"
                description="Crewsor's Media Usage Policy | At Crewsor, we value your privacy and are committed to protecting your personal data. This policy explains how we may collect and use images or videos of employees and restaurants for research or marketing purposes, in accordance with the *Personal Data Protection Act 2012 (PDPA)* of Singapore."
                url="https://www.crewsor.com/media-policy"
            />
            <div className='t-and-c media-policy'>
                <div className='tc-contents'>
                    <h1 className='t-h1'>
                        <span>Crewsor's</span> Media Usage Policy
                    </h1>
                    <p className='t-p'>
                        At Crewsor, we value your privacy and are committed to protecting your personal data.
                        This policy explains how we may collect and use images or videos of employees and restaurants for research or marketing purposes,
                        in accordance with the *Personal Data Protection Act 2012 (PDPA)* of Singapore.
                    </p>
                    <div className='t-ul-div'>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>1. Collection and Use of Media</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    From time to time, Crewsor and our partner restaurants may collect images or videos of employees or restaurant environments during work assignments or events. These may be used for:
                                </li>
                            </ul>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    - Internal Research: To improve our services and understand job market trends.
                                </li>
                                <li className='t-li'>
                                    - Marketing and Promotion: For use in marketing materials, including but not limited to social media, website content, brochures, and advertisements, to showcase our services and restaurant partners.
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>2. Consent for Media Usage</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    By using the Crewsor app and accepting work assignments through the platform, you consent to the collection and use of your image or video for the purposes outlined above. We will ensure that:
                                </li>
                            </ul>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    - You are informed when such media is being collected.
                                </li>
                                <li className='t-li'>
                                    - You have the opportunity to opt-out if you do not wish for your image or video to be used for marketing purposes.
                                </li>
                            </ul>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    If you wish to withdraw your consent after media has been collected, you may contact us at [Insert Contact Information], and we will make reasonable efforts to remove the media from future use.
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>3. Ownership and Rights</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    Crewsor and the restaurants retain the rights to all images or videos collected during assignments for research or marketing purposes. However, we will not sell or provide your media to third parties without your explicit consent, except when used in Crewsor’s or the restaurant’s own marketing channels.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>4. Use by Restaurants</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    Partner restaurants may also use images or videos of their premises and staff for their own marketing purposes. By accepting work through Crewsor, you agree that restaurants may use media collected during your assignment for marketing purposes, subject to the same terms of this policy.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>5. Protection of Media</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    All media collected will be handled in accordance with our data protection practices. Any images or videos containing identifiable personal data will be stored securely and used only for the purposes stated above.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>6. Opt-Out</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    If you do not wish for your images or videos to be used for marketing purposes, you may opt-out by:
                                </li>
                            </ul>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    - Indicating your preference during job assignment or media collection.
                                </li>
                                <li className='t-li'>
                                    - Contacting us at [Insert Contact Information] to request that your images or videos not be used in future marketing materials.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>7. Modifications to Policy</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    Crewsor reserves the right to update this Media Usage Policy from time to time. Any significant changes will be communicated to users through email or app notifications.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con' id='contact-us'>
                            <h2 className='t-h2 t-c-text'>11. Contact Us</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    If you have any concerns or questions regarding this policy or your rights related to media usage, please contact us at:
                                </li>
                            </ul>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    Address: <br />
                                    Crewsor Pte Ltd<br />
                                    35 Kallang Pudding Roaed,<br />
                                    #06-12 Tong Lee Building Block A<br />
                                    Singapore 349314
                                </li>
                            </ul>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    Contact Number: +65 555-567-8901
                                </li>
                                <li className='t-li'>
                                    Email: <a href='mailto:support@crewsor.com' className='tc-mail-a'> support@crewsor.com</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MediaPolicy
