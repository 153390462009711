import React, { useState } from 'react'
import contactimg from "../assets/images/contact-bg.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { saveContactForm } from '../authiapis/backendcall';
import { useLocation } from 'react-router-dom';

const Contactus = () => {

    const location = useLocation();
    const isBusinessPath = location.pathname === "/business";

    const [formData, setFormData] = useState({
        name: '',
        nricNo: '',
        emailAddress: '',
        phoneNo: '',
        message: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phoneNo') {
            // Only allow numeric values and limit to 8 digits
            const sanitizedValue = value.replace(/\D/g, '').slice(0, 8);

            setFormData({
                ...formData,
                [name]: sanitizedValue
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log('e :>> ', e.target.name, e.target.value);
        console.log('formData :>> ', formData);

        const validationErrors = validateForm(formData);


        if (Object.keys(validationErrors).length == 0) {
            // Clear any previous errors
            setErrors({});
            const formattedData = {
                ...formData,
                phoneNo: `+65${formData.phoneNo}`
            };
            const response = await saveContactForm(formattedData)
            console.log(formattedData)

            // Form submission logic goes here
            console.log("Form submitted successfully");
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        let errors = {};
        const phoneRegex = /^[89]\d{7}$/;
        // const nricPattern = /^[STFG]\d{7}[A-Z]$/; 
        const nricPattern = /^[ST]\d{7}N$/;
        const uenPattern = /^\d{9}[A-Z]$/;

        // Full Name validation
        if (!data.name.trim()) {
            errors.name = 'Name is required';
        }

        // Email validation  
        if (!data.emailAddress) {
            errors.emailAddress = 'Email Address is required';
        }
        else if (!/\S+@\S+\.\S+/.test(data.emailAddress)) {
            errors.emailAddress = 'Invalid Email address';
        }

        // Phone validation (optional)
        if (!data.phoneNo) {
            errors.phoneNo = "Phone number is required";
        } else if (!phoneRegex.test(data.phoneNo)) {
            errors.phoneNo = "Invalid Singapore phone number (must be 8 digits starting with 8 or 9)";
        }

        // if (!data.nricNo.trim()) {
        //     errors.nricNo = "NRIC number is required";
        // } else if (!nricPattern.test(data.nricNo)) {
        //     errors.nricNo = "Invalid NRIC number format";
        // }
        if (!data.nricNo.trim()) {
            errors.nricNo = isBusinessPath ? "UEN number is required" : "NRIC number is required";
        } else if (isBusinessPath && !uenPattern.test(data.nricNo)) {
            errors.nricNo = "Invalid UEN format (must be 9 digits followed by an uppercase letter)";
        } else if (!isBusinessPath && !nricPattern.test(data.nricNo)) {
            errors.nricNo = "Invalid NRIC format (must start with S or T, 7 digits, and end with an uppercase letter)";
        }

        // if (!data.uen.trim()) {
        //     errors.uen = "UEN number is required";
        // } else if (!uenPattern.test(data.uen)) {
        //     errors.uen = "Invalid UEN format (must be 9 digits followed by an uppercase letter)";
        // }

        // Message validation
        if (!data.message.trim()) {
            errors.message = 'Message is required';
        }
        console.log('errors :>> ', errors);
        return errors;
    };

    return (
        <div className='homepage'>
            <section className='contact' id='contact'>
                <LazyLoadImage src={contactimg} alt='contact' className='contact-img' />
                <div className='contact-content'>
                    <div className='t-head'>
                        <h2 className='h2-text-1'>Contact Us</h2>
                        <p className='contact-p'>
                            Please fill out the form below, and one of our team members will reach out to you to address your inquiry. Rest <br className='break t-break' />
                            assured, your information will remain private.
                        </p>
                    </div>
                    <div className='row g-4 form-row'>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div class="">
                                <label for="basic-url" class="form-label f-label">
                                    {
                                        `${location.pathname === "/business" ? "Restaurent Name*" : "Name*"}`
                                    }
                                </label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder=
                                        {
                                            `${location.pathname === "/business" ? "Restaurent Name" : "Name"}`
                                        }
                                        name="name" value={formData.fullname} onChange={handleChange} id="basic-url" aria-describedby="basic-addon3 basic-addon4" />
                                </div>
                                {errors.name && <div className='c-error-p'>{errors.name} </div>}

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div class="">
                                <label for="basic-url" class="form-label f-label">
                                    {
                                        `${location.pathname === "/business" ? "UEN Number*" : "NRIC Number*"}`
                                    }
                                </label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder={
                                        `${location.pathname === "/business" ? "UEN Number" : "NRIC Number"}`
                                    } name="nricNo" value={formData.nricNo} onChange={handleChange} id="basic-url" aria-describedby="basic-addon3 basic-addon4" />
                                </div>
                                {errors.nricNo && <div className='c-error-p'>{errors.nricNo} </div>}

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div class="">
                                <label for="basic-url" class="form-label f-label">Email*</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder='Email' name='emailAddress' onChange={handleChange} id="basic-url" aria-describedby="basic-addon3 basic-addon4" />
                                </div>
                                {errors.emailAddress && <div className='c-error-p'>{errors.emailAddress} </div>}
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div class="">
                                <label for="basic-url" class="form-label f-label">Phone*</label>
                                <div class="input-group">
                                    <span class="input-group-text">+65</span>
                                    <input type="number" class="form-control" placeholder='Phone Number' name='phoneNo' value={formData.phoneNo} onChange={handleChange} id="basic-url" aria-describedby="basic-addon3 basic-addon4" />
                                </div>
                                <div className='c-error-p'>{errors.phoneNo} </div>




                            </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-12'>
                            <div class="d-flex flex-column">
                                <label for="exampleFormControlTextarea1" class="form-label">Message*</label>
                                <textarea class="form-control f-textarea" id="exampleFormControlTextarea1" name='message' onChange={handleChange} rows="3" placeholder="Tell us about your query"></textarea>
                                {errors.message && <div className='c-error-p'>{errors.message} </div>}
                            </div>
                        </div>
                        <div className='col-lg-6 col-6 col-12'>
                            <button className='btn-send ' onClick={handleSubmit}>
                                Send Message
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contactus;
