import "./assets/css/style.css"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Homepage from "./pages/Homepage";
import Footer from "./components/layout/Footer";
import HireStaff from "./pages/HireStaff"
import TermsandCondition from "./pages/TermsandCondition";
import PDPA from "./pages/PDPA";
import MediaPolicy from "./pages/MediaPolicy";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/business" element={<HireStaff />} />
          <Route path="/terms-and-conditions" element={<TermsandCondition />} />
          <Route path="/pdpa-policy" element={<PDPA />} />
          <Route path="/media-policy" element={<MediaPolicy />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
