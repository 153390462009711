import axios from "axios";
import config from "./config";

export const makeApiRequest = async (params) => {
    console.log('params :>> ', params);
    console.log('process.env.REACT_APP_BACKENDURL', process.env.REACT_APP_BACKENDURL)

    try {
        const headers = {
            "Content-Type": "application/json", // For JSON
        }
        let response;

        switch (params.method.toUpperCase()) {

            case "POST":
                const requestData = params.data instanceof FormData ? params.data : JSON.stringify(params.data);
                response = axios.post(`${process.env.REACT_APP_BACKENDURL}${params.url}`, requestData, { headers })
                break;
            default:
                throw new Error(`Unsupported method: ${params.method}`);
        }
        return response;
    }
    catch (error) {
        console.log("axios error", error);
        throw error;
    }
}
