import React, { Fragment, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import appbtn from "../assets/images/Appstorebtn.svg"
import googlebtn from "../assets/images/Googleplaybtn.svg"
import aboutimg from "../assets/images/abtimg.svg"
import qrcode from "../assets/images/qrcode.svg"
import FAQ from '../components/FAQ'
import Benefits from '../components/Benefits';
import downloadimg from "../assets/images/bgwebp.webp"
import Contactus from '../components/Contactus';
import bgimg from "../assets/images/c-bg.svg"
import bannerbg2 from "../assets/images/bannerbg2.svg"
import gcarddownload from "../assets/images/crewsor-cd.svg"
import mobilebannerbg from "../assets/images/mt-bannerbg.svg"
import mobilebanimg from "../assets/images/mobilebanimg.svg"
import gm1 from "../assets/images/gm1.svg"
import gm2 from "../assets/images/gm2.svg"
import gm3 from "../assets/images/gm3.svg"
import gm4 from "../assets/images/gm4.svg"
import gm5 from "../assets/images/gm5.svg"
import gm6 from "../assets/images/gm6.svg"
import homeimg from "../assets/images/home-img.svg"
import Meta from '../components/Meta';









// accordiondata 

const accordionData = [
    {
        id: 1,
        title: 'How does Crewsor help me find work in the F&B industry?',
        content: 'Crewsor connects you with a variety of F&B job opportunities that match your skills and schedule. You can browse open shifts, apply directly, and get hired quickly—all in one easy-to-use platform.',
    },
    {
        id: 2,
        title: 'Do I need prior experience to apply for jobs on Crewsor?',
        content: 'While having experience in the F&B industry is beneficial, it’s not always required. Many businesses on Crewsor offer opportunities for workers of all experience levels, from entry-level to seasoned professionals.',
    },
    {
        id: 3,
        title: 'How do I apply for a shift?',
        content: 'Once you’ve signed up and completed your profile, simply browse available shifts, click on the ones that interest you, and apply directly through the platform. You’ll receive updates as soon as the employer reviews your application.',
    },
    {
        id: 4,
        title: 'Can I set my work schedule?',
        content: 'Yes! Crewsor allows you to find shifts that fit your availability. You have the freedom to choose when and where you want to work, giving you full control over your schedule.',
    },
    {
        id: 5,
        title: 'How do I get paid for my shifts?',
        content: 'Payment is processed directly through Crewsor. After completing a shift, your payment will be tracked and automatically processed, ensuring you get paid promptly and accurately.',
    },
    {
        id: 6,
        title: 'Is there a fee to use Crewsor to find jobs?',
        content: 'No, Crewsor is free for job seekers. You can create a profile, browse opportunities, and apply for shifts without any cost.',
    },
    {
        id: 7,
        title: 'Can I apply for multiple shifts at once?',
        content: 'Absolutely. You can apply for as many shifts as you like, as long as they fit your availability. Crewsor makes it easy to manage multiple applications and track your schedule.',
    },

];



// benefitsdata

const benefitsData = [

    {
        title: "Flexible Work Opportunities",
        description: "Crewsor offers a wide range of shifts that fit various schedules, allowing staff to choose work that aligns with their availability.",
        isBlack: false,
    },
    {
        title: "Easy Application Process",
        description: "The user-friendly app simplifies the job application process, enabling staff to apply for multiple shifts with just a few taps.",
        isBlack: true,
    },
    {
        title: "Instant Notifications",
        description: " Staff receive real-time notifications about new shifts and updates, ensuring they never miss out on opportunities..",
        isBlack: true,
    },
    {
        title: "Automatic Hiring",
        description: " Once a shift is applied for, staff are connected directly with businesses looking for qualified workers, streamlining the hiring process.",
        isBlack: false,
    },
    {
        title: "Weekly Payments",
        description: "Crewsor ensures prompt weekly payments for completed shifts, providing staff with reliable and timely compensation.",
        isBlack: false,
    },
    {
        title: "Diverse Job Listings",
        description: " Staff can explore a variety of roles across different F&B establishments, allowing them to find positions that match their skills and interests.",
        isBlack: true,
    },
    {
        title: "Skill Development",
        description: "Working in various environments helps staff gain valuable experience and skills that enhance their career prospects in the F&B industry.",
        isBlack: true,
    },
    {
        title: "Supportive Community",
        description: "Crewsor fosters a sense of community among staff, offering networking opportunities and connections within the industry.",
        isBlack: false,
    },



];


const Homepage = () => {

    useEffect(() => {
        // Check if there's a hash in the URL
        if (window.location.hash) {
            const id = window.location.hash.substring(1); // Extract the ID from the hash
            const element = document.getElementById(id);

            // Optionally, you can set focus or any other indication if needed
            if (element) {
                element.focus(); // If it's focusable, this can help indicate navigation.
            }
        }
    }, []);

    return (

        <Fragment>
            <Meta
                title="Find Flexible F&B Shifts That Fit Your Schedule | Crewsor"
                description="Join Crewsor to discover flexible F&B shifts in Singapore. Work when it suits you, choose nearby venues, and get paid weekly. Download the Crewsor app today!"
                url="https://www.crewsor.com/"
            />

            <div className="homepage">
                <section className='banner-section'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-12 order-lg-2 order-md-1 order-1'>
                            <div className='bc-right '>
                                <LazyLoadImage src={homeimg} alt='bannerimg' className='hs-banner-img img-fluid ' width={599} height={353} />
                                {/* <LazyLoadImage src={mobilebanimg} alt='banner' className='desktop-hide banner-img img-fluid' /> */}
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12 order-lg-1 order-md-2 order-2'>
                            <div className='bc-left'>
                                <h1 className='h1-text-1'>
                                    <span>Flexible</span> Shifts,<br />
                                    Reliable <span>Pay</span><br />
                                </h1>
                                <p className='p-text-1'>
                                    With Crewsor, finding F&B shifts in Singapore is a breeze. Choose from nearby venues that fit your schedule and enjoy weekly payments for your hard work. Experience the freedom of working when you want!
                                </p>
                                <div className='bc-btn-sec'>
                                    <div className='btn-ps'>
                                        <div className='btn-tool-div'>
                                            <button className='app-btn'>
                                                <LazyLoadImage src={appbtn} alt='appstore' />
                                            </button>
                                            <p className='p-cs-text'>Coming Soon</p>
                                        </div>
                                        <div className='btn-tool-div'>
                                            <button className='app-btn'>
                                                <LazyLoadImage src={googlebtn} alt='appstore' />
                                            </button>
                                            <p className='p-cs-text'>Coming Soon</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='about' id="about">
                    <div className='about-content'>
                        <div className='t-head'>
                            <h2 className='h2-text-1'>Connecting Skilled Professionals with F&B Opportunities</h2>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card about-card'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-9 col-12'>
                                            <div className='ac-left'>
                                                <h3 className='h3-text-1'>Who we are?</h3>
                                                <p className='p-text-3'>Crewsor is Singapore's leading staff recruiting
                                                    platform, which is designed for the F&B industry. We
                                                    connect restaurents and outlets with skilled staff
                                                    quickly and efficiently, helping business thrive and
                                                    individuals find flexible work opportunities.
                                                </p>
                                            </div>
                                            <LazyLoadImage src={aboutimg} className='about-img' alt='aboutimg' />

                                        </div>

                                        {/* <div className='ac-right'>
                                            <LazyLoadImage src={aboutimg} className='about-img' alt='aboutimg' />
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="grid-card" id='how-it-works'>
                    <div className='grid-card-section'>
                        <div className='t-head'>
                            <h2 className='h2-text-1'>Getting Started with Crewsor: A Staff Guide</h2>
                        </div>
                        <div className='row g-4 grid-card-row'>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='card g-card h-100'>
                                    <h3 className="g-card-h-text">Download the <br /> Crewsor App</h3>
                                    <div className='g-card-body'>
                                        <div className='g-card-left'>
                                            <p className='g-card-p'>Start by downloading and <br className='break t-break' />
                                                Installing the Crewsor app <br className='break t-break' />
                                                from the Google Play Store <br className='break t-break' />
                                                or the Apple App Store
                                            </p>
                                            <LazyLoadImage src={gcarddownload} alt='download' />
                                        </div>
                                        <div className='g-card-right '>
                                            <LazyLoadImage src={gm1} alt='grid-card' />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='card g-card h-100'>
                                    <h3 className="g-card-h-text">Sign<br /> Up</h3>
                                    <div className='g-card-body'>
                                        <div className='g-card-left'>
                                            <p className='g-card-p'>Create Your Profile by <br className='break t-break' />
                                                Signing up. Make sure for <br className='break t-break' />
                                                provide all required <br className='break t-break' />
                                                details, including your <br className='break t-break' />
                                                experience and availability.
                                            </p>
                                            <LazyLoadImage src={gcarddownload} alt='download' />
                                        </div>
                                        <div className='g-card-right'>
                                            <LazyLoadImage src={gm2} alt='grid-card' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='card g-card h-100'>
                                    <h3 className="g-card-h-text">Browse Available<br /> Shifts</h3>
                                    <div className='g-card-body'>
                                        <div className='g-card-left'>
                                            <p className='g-card-p'>Explore a Wide range<br className='break t-break' />
                                                shifts in the F&B industry <br className='break t-break' />
                                                that fit your schedule. You  can <br className='break t-break' />
                                                filter opportunities   based on <br className='break t-break' />
                                                your    preferences.
                                            </p>
                                            <LazyLoadImage src={gcarddownload} alt='download' />
                                        </div>
                                        <div className='g-card-right'>
                                            <LazyLoadImage src={gm3} alt='grid-card' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='card g-card h-100'>
                                    <h3 className="g-card-h-text">Automatic<br /> Hiring</h3>
                                    <div className='g-card-body'>
                                        <div className='g-card-left'>
                                            <p className='g-card-p'>Once you apply for a shift,<br className='break t-break' />
                                                we automatically connects you <br className='break t-break' />
                                                with business looking for skilled  <br className='break t-break' />
                                                staff. You'll be hired based on  <br className='break t-break' />
                                                the shifts you provide.
                                            </p>
                                            <LazyLoadImage src={gcarddownload} alt='download' />
                                        </div>
                                        <div className='g-card-right'>
                                            <LazyLoadImage src={gm4} alt='grid-card' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='card g-card h-100'>
                                    <h3 className="g-card-h-text">Work Your<br /> Shift</h3>
                                    <div className='g-card-body'>
                                        <div className='g-card-left'>
                                            <p className='g-card-p'>Work your scheduled shifts<br className='break t-break' />
                                                and track your hours efforlessly<br className='break t-break' />
                                                through the app. Everything is   <br className='break t-break' />
                                                managed in one convenient  <br className='break t-break' />
                                                place.
                                            </p>
                                            <LazyLoadImage src={gcarddownload} alt='download' />
                                        </div>
                                        <div className='g-card-right'>
                                            <LazyLoadImage src={gm5} alt='grid-card' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='card g-card h-100'>
                                    <h3 className="g-card-h-text">Get Paid<br /> Weekly</h3>
                                    <div className='g-card-body'>
                                        <div className='g-card-left'>
                                            <p className='g-card-p'>Enjoy the convenience of<br className='break t-break' />
                                                weekly payments. Crewsor <br className='break t-break' />
                                                ensures  that you receive your  <br className='break t-break' />
                                                earnings promptly for the  <br className='break t-break' />
                                                shifts you complete.
                                            </p>
                                            <LazyLoadImage src={gcarddownload} alt='download' />
                                        </div>
                                        <div className='g-card-right'>
                                            <LazyLoadImage src={gm6} alt='grid-card' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Benefits benefitsData={benefitsData} bhead={" Maximizing Your Potential with Crewsor: Benefits for Workers"} />
                <section className='d-crewsor' id='download'>
                    <div className='dc-content'>
                        <div className='t-head'>
                            <h2 className='h2-text-1'>Download the Crewsor App: <br className='break' /> Your Gateway to F&B Opportunities</h2>
                        </div>
                        <div className='dc-center-t'>
                            <p className='p-text-7'>
                                Join Singapore's leading platform for F&B professionals. Download the Crewsor app to easily find shifts that match <br className='break t-break' />
                                your skills and availability. Get hired quickly, manage your schedule, and enjoy weekly payments-all at your fingertips!.
                            </p>
                        </div>
                        <div className='dc-card-section'>
                            <div className='row g-4'>
                                <div className='col-lg-7 col-md-7 col-12 dc-left'>
                                    <div className="download-bg">
                                        <img src={downloadimg} alt="image" className='img-fluid' />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-5 col-12 dc-right'>
                                    <div className='col-lg-12 col-md-12 col-12 '>
                                        <div className='card dc-r-card'>
                                            <div className='dcr-head'>
                                                <h2 className='h2-text-3'>Download the app </h2>
                                            </div>
                                            <div className='dc-r-card-body'>
                                                <div className='dc-btn-div'>
                                                    <button className='app-btn'>
                                                        <LazyLoadImage src={appbtn} alt='appstore' />
                                                        <span className="tooltip">Coming Soon</span>
                                                    </button>
                                                    <button className='app-btn'>
                                                        <LazyLoadImage src={googlebtn} alt='appstore' />
                                                        <span className="tooltip">Coming Soon</span>
                                                    </button>
                                                </div>
                                                <LazyLoadImage src={qrcode} alt='qrcode' className='qr-code-img' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LazyLoadImage src={bgimg} className='bg-img' alt='img' />
                </section>
                <FAQ accordionData={accordionData} />
                <Contactus />
            </div>
        </Fragment>
    )
}

export default Homepage
