import React, { Fragment } from 'react'
import Meta from '../components/Meta'

const PDPA = () => {
    return (
        <Fragment>
            <Meta
                title="Personal Data Protection Act (PDPA) | Crewsor"
                description="Crewsor's Personal Data Protection Act (PDPA) | Join Crewsor to discover flexible F&B shifts in Singapore. Work when it suits you, choose nearby venues, and get paid weekly. Download the Crewsor app today!"
                url="https://www.crewsor.com/pdpa-policy"
            />

            <div className='pdpa t-and-c'>
                <div className='tc-contents'>
                    <h1 className='t-h1'>
                        <span>Crewsor's</span> Personal Data Protection Act (PDPA)
                    </h1>
                    <p className='t-p'>
                        At Crewsor, we take your privacy seriously. This policy outlines how we collect, use, disclose, and protect your personal data in accordance with the *Personal Data Protection Act 2012 (PDPA)* of Singapore.
                    </p>
                    <div className='t-ul-div'>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>1. Collection of Personal Data</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    We collect and process the following personal data when you use the Crewsor app:
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Employees (Singaporeans and PRs):</h2>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    - NRIC Number
                                </li>
                                <li className='t-li'>
                                    - Name
                                </li>
                                <li className='t-li'>
                                    - Singapore Mobile Number
                                </li>
                                <li className='t-li'>
                                    - Email Address
                                </li>
                                <li className='t-li'>
                                    - Employment-related data (e.g., work availability, payment details)
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>F&B Restaurants:</h2>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    - Company Name
                                </li>
                                <li className='t-li'>
                                    - Restaurant Name
                                </li>
                                <li className='t-li'>
                                    - UEN Number
                                </li>
                                <li className='t-li'>
                                    - Business Address
                                </li>
                                <li className='t-li'>
                                    - Contact Details (Email, Phone Number)
                                </li>
                                <li className='t-li'>
                                    - Point of Contact (POC) Information
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>2. Purpose of Data Collection</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    The personal data collected from employees and F&B restaurants is used for the following purposes:
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Employee Data:</h2>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    - Verify eligibility to work (only Singaporeans and PRs)
                                </li>
                                <li className='t-li'>
                                    - Facilitate job matching between employees and restaurants
                                </li>
                                <li className='t-li'>
                                    - Manage work assignments and payments
                                </li>
                                <li className='t-li'>
                                    - Communicate with employees about job updates and other     service-related information
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>F&B Restaurant Data:</h2>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    - Verify business information for recruitment purposes
                                </li>
                                <li className='t-li'>
                                    - Facilitate hiring of temporary staff
                                </li>
                                <li className='t-li'>
                                    - Provide communication for service usage, billing, and support
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>3. Consent</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    By using the Crewsor app, you consent to the collection, use, and disclosure of your personal data as described in this policy. You can withdraw consent at any time by contacting us at [Insert Contact Information], but this may affect your ability to use our services.
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>4. Use and Disclosure of Personal Data</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    Crewsor ensures that your personal data is used only for its intended purposes. We do not sell or disclose your personal data to third parties unless:
                                </li>
                            </ul>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    - Required by law.
                                </li>
                                <li className='t-li'>
                                    - Necessary to provide the service (e.g., sharing data with F&B restaurants for job matching or payments).
                                </li>
                            </ul>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    In all instances, your data is shared securely and only with authorized personnel or partners.
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>5. Protection of Personal Data</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    We take appropriate measures to protect your personal data from unauthorized access, use, or disclosure. These include:
                                </li>
                            </ul>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    - Encryption of sensitive information.
                                </li>
                                <li className='t-li'>
                                    - Regular security reviews and updates to our systems.
                                </li>
                                <li className='t-li'>
                                    - Limiting access to personal data only to employees or agents who need it for their job functions.
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>6. Retention of Personal Data</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    Personal data will be retained for as long as necessary to fulfill the purpose for which it was collected, or as required by law. Once data is no longer needed, it will be securely deleted or anonymized.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>7. Access and Correction</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    You may request access to or correction of your personal data held by us. If you wish to:
                                </li>
                            </ul>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    - Access your personal data.
                                </li>
                                <li className='t-li'>
                                    - Correct or update inaccurate data.
                                </li>
                                <li className='t-li'>
                                    - Withdraw consent for us to use your personal data.
                                </li>
                            </ul>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    Please contact us at  <a href='mailto:support@crewsor.com' className='tc-mail-a'> support@crewsor.com</a>.
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>8. Data Breach Notification</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    In the event of a data breach that affects your personal data, we will notify you and the relevant authorities as required by law. We will also take immediate steps to mitigate the breach and prevent future occurrences.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>9. Third-Party Services</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    Where we use third-party service providers (e.g., payment processors), these providers are contractually obligated to adhere to strict privacy and confidentiality obligations.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>10. Changes to Policy</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    Crewsor may update this PDPA policy from time to time to ensure it is consistent with legal and regulatory requirements. We will notify users of significant changes via email or app notifications.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con' id='contact-us'>
                            <h2 className='t-h2 t-c-text'>11. Contact Us</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    If you have any questions or concerns about our PDPA policy, please contact us at:
                                </li>
                            </ul>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    Address: <br />
                                    Crewsor Pte Ltd<br />
                                    35 Kallang Pudding Roaed,<br />
                                    #06-12 Tong Lee Building Block A<br />
                                    Singapore 349314
                                </li>
                            </ul>
                            <ul className='t-ul ul-pdpa'>
                                <li className='t-li'>
                                    Contact Number: +65 555-567-8901
                                </li>
                                <li className='t-li'>
                                    Email: <a href='mailto:support@crewsor.com' className='tc-mail-a'> support@crewsor.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default PDPA
