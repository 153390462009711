import React, { Fragment } from 'react'
import Meta from '../components/Meta'

const TermsandCondition = () => {
    return (


        <Fragment>
            <Meta
                title="Terms and Conditions | Crewsor"
                description="Crewsor's Terms of Service | Join Crewsor to discover flexible F&B shifts in Singapore. Work when it suits you, choose nearby venues, and get paid weekly. Download the Crewsor app today!"
                url="https://www.crewsor.com/terms-and-conditions"
            />
            <div className='t-and-c'>
                <div className='tc-contents'>
                    <h1 className='t-h1'>
                        <span>Crewsor's</span> Terms of Service
                    </h1>
                    <p className='t-p'>
                        By clicking "Accept" or checking the box during account creation, or by using Crewsor's website or
                        mobile application (collectively, the "Platform"), you irrevocably agree to Crewsor's Terms of Service. These terms may be updated periodically.
                        Additional terms and registration requirements may apply to certain features of the Platform.
                    </p>
                    <div className='t-ul-div'>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Crewsor's Services and Responsibilities</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    1.1. Crewsor is an online and mobile platform owned and operated by Crewsor Pte. Ltd. that enables food and beverage businesses (the "Customer") to hire temporary staff for specific shifts (the "Service") with a platform fee (the "Service Fee"). The service is currently only available in Singapore.
                                </li>
                                <li className='t-li'>
                                    1.2. Once a Customer creates an account with Crewsor,
                                    they can post shift requests (known as "Shifts") on the Platform, including the payment rate ("Pay") offered to the Worker. Registered users of Crewsor (referred to as "Workers") can sign up for available Shifts
                                </li>
                                <li className='t-li'>
                                    1.3. By accepting a Shift, a Worker agrees to the Pay specified. All other terms related to the performance of the Shift are determined between the Customer and the Worker
                                </li>
                                <li className='t-li'>
                                    1.4. Crewsor will match eligible Workers with the Customer, and the Customer will provide the necessary details regarding the Shift to the Worker.
                                </li>
                                <li className='t-li'>
                                    1.5. All Workers using Crewsor are either Singapore citizens or permanent residents legally allowed to work in Singapore. By registering with Crewsor, Workers confirm that they meet these criteria.
                                </li>
                                <li className='t-li'>
                                    1.6. Customers using the Platform are legally incorporated companies and are responsible for maintaining all required approvals, permits, and licenses for their business operation
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Fees and Payment</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    2.1. From the second Shift onward, Crewsor charges a Platform Service Fee of S$8 per Worker per Shift. This fee covers the hosting and administration of the Platform.
                                </li>
                                <li className='t-li'>
                                    2.2. Customers are required to provide Crewsor with the total number of Shifts completed by Workers and the corresponding Pay each week via the specified communication method.
                                </li>
                                <li className='t-li'>
                                    2.3. Payments to Workers will be made weekly, with Crewsor processing payments on Mondays at 6 PM (Singapore Time), or the next business day if Monday is a public holiday. Crewsor will issue an invoice to the Customer, including the Pay and Service Fee. Once registered for GST, Crewsor will add it as required.
                                </li>
                                <li className='t-li'>
                                    2.4. The Customer must pay the invoice within three (3) days from issuance unless otherwise agreed upon in writing. If a payment card is provided, Crewsor is authorized to charge it.
                                </li>
                                <li className='t-li'>
                                    2.5. Customers are responsible for keeping all necessary records regarding Worker employment and ensuring compliance with any applicable fees, taxes, CPF, and other obligations as required by law.
                                </li>
                                <li className='t-li'>
                                    2.6. Workers are also responsible for providing accurate information to Crewsor and the Customer and ensuring compliance with their legal obligations, including CPF and tax requirements.
                                </li>
                            </ul>

                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Relationship Between Customers, Workers, and Crewsor</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    3.1. Crewsor acts as an independent intermediary, connecting Customers with Workers via the Platform. Crewsor is not the employer of Workers nor an employee of Customers.
                                </li>
                                <li className='t-li'>
                                    3.2. Workers are considered independent contractors of Customers. In cases where an employer-employee relationship exists, the Customer is the employer.
                                </li>
                                <li className='t-li'>
                                    3.3. All parties—Customers, Workers, and Crewsor—agree that their relationship is that of independent contractors, with no employment, agency, joint venture, or partnership relationship implied or created through the use of the Platform.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Mispresentation</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    4.1. Customers and Workers are only permitted to register accounts for themselves on Crewsor
                                </li>
                                <li className='t-li'>
                                    4.2. Attempting to use another Customer's or Worker’s account is prohibited and will result in account termination.
                                </li>
                                <li className='t-li'>
                                    4.3. Misrepresenting identity, qualifications, business nature, Shift conditions, or Pay is not allowed.
                                </li>
                                <li className='t-li'>
                                    4.4. Fraudulent impersonation of others or misrepresenting a relationship with a business or organization is strictly prohibited.
                                </li>
                                <li className='t-li'>
                                    4.5. Any form of misrepresentation may result in the immediate termination of the relevant account, and Crewsor reserves the right to take legal action.
                                </li>
                                <li className='t-li'>
                                    4.6. Crewsor will report any criminal activity to the relevant authorities and provide necessary information.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Intellectual Property & Confidentiality</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    5.1. Crewsor's names, logos, trademarks, and other proprietary materials on the Platform are protected by intellectual property laws. No rights or licenses are granted without Crewsor’s prior consent.
                                </li>
                                <li className='t-li'>
                                    5.2. All Platform content, including text, graphics, and software, belongs exclusively to Crewsor. Any use of this content is restricted to personal, non-commercial purposes.
                                </li>
                                <li className='t-li'>
                                    5.3. The content and trademarks on the Platform cannot be used to defame or discredit Crewsor or others
                                </li>
                                <li className='t-li'>
                                    5.4. Users may not modify, copy, reproduce, or commercially exploit any content from the Platform without Crewsor’s written permission.
                                </li>
                                <li className='t-li'>
                                    5.5. Attempts to decompile or reverse-engineer Crewsor's platform technology are prohibited.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Indemnity</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    You agree to indemnify Crewsor, its affiliates, and representatives from any losses or damages arising from your use of the Platform, your breach of these Terms, or failure to comply with applicable laws. These indemnity obligations will continue after your use of the Platform ends.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Limitation of Liability</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    7.1. Crewsor does not assume responsibility for the accuracy or completeness of any information on the Platform.
                                </li>
                                <li className='t-li'>
                                    7.2. Crewsor is not liable for any losses or damages arising from the use or inability to use the Platform.
                                </li>
                                <li className='t-li'>
                                    7.3. Your sole remedy for dissatisfaction with the Platform is to stop using it
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Personal Data</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    In accordance with the Personal Data Protection Act 2012 ("PDPA"), Crewsor is committed to the following obligations:
                                </li>
                                <li className='t-li'>
                                    8.1. Crewsor will collect, use, and/or disclose your personal data only when you have provided consent. You may withdraw your consent at any time by giving a reasonable notice of five (5) working days. Once such a withdrawal request is received and processed, Crewsor will stop the collection, use, and/or disclosure of your personal data
                                </li>
                                <li className='t-li'>
                                    8.2. Your consent to collect, use, and/or disclose personal data will not be required beyond what is reasonably necessary for Crewsor to provide its services.
                                </li>
                                <li className='t-li'>
                                    8.3. Crewsor will inform you of the purpose for which your personal data is collected, used, and/or disclosed at the time of obtaining consent. Typical purposes include communication, identity verification, payments, and record-keeping.
                                </li>
                                <li className='t-li'>
                                    8.4. You may request from Crewsor information about the personal data held in its possession and details on how it has been used or disclosed in the past year. You may also request corrections to any inaccuracies or omissions in your personal data, which Crewsor will address as soon as reasonably possible.
                                </li>
                                <li className='t-li'>

                                    8.5. Crewsor will make every effort to ensure the accuracy and completeness of the personal data it collects.
                                </li>
                                <li className='t-li'>
                                    8.6. Crewsor has implemented security measures to protect personal data from unauthorized access, use, collection, or disclosure.
                                </li>
                                <li className='t-li'>
                                    8.7. Personal data will only be retained for as long as it is necessary for business or legal purposes.
                                </li>
                                <li className='t-li'>
                                    8.8. If personal data needs to be transferred overseas, Crewsor will ensure the destination country offers a comparable level of data protection as required by the PDPA.
                                </li>
                                <li className='t-li'>
                                    8.9. In the event of a data breach that results in or is likely to cause significant harm to individuals, or impacts at least 500 people, Crewsor will notify the Personal Data Protection Commission ("PDPC") and affected individuals as soon as practicable.
                                </li>
                                <li className='t-li'>
                                    8.10. If you have any inquiries regarding Crewsor’s data protection practices, policies, or complaints processes, you may contact Crewsor’s data protection officer at
                                    <a href='mailto: dataprotection@crewsor.com' className='tc-mail-a'> dataprotection@crewsor.com</a>.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Confidentiality</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    The parties acknowledge that any oral or written information exchanged between them in connection with the service is considered confidential. Each party agrees to maintain the confidentiality of such information and will not disclose it to any third parties without the written consent of the other party, except where: (a) the information is already public (other than through unauthorized disclosure); or (b) disclosure is required by law, regulation, or court order. This clause will remain in effect even after the termination of the Terms of Service.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Termination</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    Crewsor reserves the right to terminate the Terms of Service at any time, without prior notice or penalty, at its sole discretion.
                                </li>
                                <li className='t-li'>
                                    Customers or Workers may terminate the Terms of Service by providing Crewsor with fourteen (14) days' written notice.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Amendment</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    Crewsor may modify or update these Terms of Service at any time, at its sole discretion, without prior notice. Any new or amended Terms will be published on the platform and will take effect immediately.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Survival</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    Sections 2.5, 2.6, 5, 6, 7, and 15, along with remedies for breaches, will remain in effect for a period of seven (7) years after the termination of these Terms of Service.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Remedies</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    13.1. Any remedies provided to Crewsor for a breach of these Terms will be in addition to and without prejudice to all other rights and remedies available by law.
                                </li>
                                <li className='t-li'>
                                    13.2. A waiver of any breach of these Terms will not be considered a waiver of subsequent breaches. The failure or delay in exercising any right, power, or privilege by any party will not operate as a waiver, nor will any partial exercise of a right, power, or privilege prevent further exercise.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Entire Agreement</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    14.1. These Terms of Service constitute the entire agreement between the parties and supersede all prior agreements, whether written or oral, related to the subject matter.
                                </li>
                                <li className='t-li'>
                                    14.2. Each party acknowledges that they have not relied on any representation or warranty that is not expressly included in these Terms of Service. No party will have any claims based on innocent or negligent misrepresentation not contained herein.
                                </li>
                                <li className='t-li'>
                                    14.3. Nothing in these Terms limits or excludes liability for fraud.
                                </li>
                            </ul>
                        </div>
                        <div className='div-ul-con'>
                            <h2 className='t-h2'>Governing Law and Jurisdiction</h2>
                            <ul className='t-ul'>
                                <li className='t-li'>
                                    15.1. These Terms of Service are governed by Singapore law.
                                </li>
                                <li className='t-li'>
                                    15.2. In case of a dispute related to these Terms, either party may provide Crewsor with written notice, and the parties will attempt to resolve the issue through amicable negotiation within 30 days.
                                </li>
                                <li className='t-li'>
                                    15.3. If the dispute is not resolved within 30 days, the matter will be referred to the Singapore Mediation Centre (SMC) for mediation, following SMC’s Mediation Procedure. Each party must send senior executives with authority to settle the dispute, and the mediation will be conducted in Singapore in English.
                                </li>
                                <li className='t-li'>
                                    15.4. If mediation does not resolve the dispute within 120 days, it will be referred to the Singapore courts.
                                </li>
                            </ul>
                        </div>
                        <div id='contact-us'>
                            <div className='div-ul-con'  >
                                <h2 className='t-h2'>Contact and Notices</h2>
                                <ul className='t-ul'>
                                    <li className='t-li'>
                                        All notices under these Terms should be emailed to the registered email address on file. Notices will be deemed received on the date they were sent.
                                    </li>
                                    <li className='t-li'>
                                        For any requests, inquiries, or assistance with the platform, please contact Crewsor’s Customer Support at
                                        <a href='mailto:support@crewsor.com' className='tc-mail-a'> support@crewsor.com</a>.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default TermsandCondition
