import React, { useEffect, useRef, useState } from 'react';
import logo from '../../assets/images/logo.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useLocation } from 'react-router-dom';
import menu from "../../assets/images/menu.svg"
import close from "../../assets/images/close.svg"
import { useNavigate } from 'react-router-dom';




const debounce = (func, wait = 20, immediate = true) => {
    let timeout;
    return function () {
        const context = this, args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};




const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };


    const location = useLocation();
    const [activeTab, setActiveTab] = useState('/');


    const scrollToSection = debounce((id) => {
        const section = document.getElementById(id);

        if (section) {
            const headerOffset = 10;
            const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: sectionTop,
                behavior: 'smooth'
            });
        } else {
            window.location.href = `/#${id}`;
        }
    }, 100);


    useEffect(() => {
        const hash = window.location.hash.substring(1);
        if (hash) {
            scrollToSection(hash);
        }
    }, []);

    useEffect(() => {
        const currentPath = location.pathname;
        setActiveTab(currentPath);  // Set active tab based on the current route
    }, [location]);

    const handleTabClick = (path) => {
        setActiveTab(path);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };






    return (
        <div className="top-navbar">
            <div className="navbar">
                <div className="nav-content">
                    <Link to="/" reloadDocument>
                        <LazyLoadImage src={logo} className="logo" alt="logo" />
                    </Link>
                    <div className='mobile-toggle-div  desktop-hide w-100'>
                        <ul className="nav nav-pills topnav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <Link
                                    to="/"
                                    className={`nav-link ${activeTab === '/' ? 'active' : ''}`}
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected={activeTab === '/'}
                                    onClick={() => handleTabClick('/')}
                                >
                                    Find Work
                                </Link>
                            </li>
                            <li className="nav-item" role="presentation">
                                <Link
                                    to="/business"
                                    className={`nav-link ${activeTab === '/business' ? 'active' : ''}`}
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected={activeTab === '/business'}
                                    onClick={() => handleTabClick('/business')}
                                >
                                    Hire Staff
                                </Link>
                            </li>
                        </ul>
                        <button className={`menu-toggle desktop-hide ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                            <span className="menu-icon">
                                {
                                    isMenuOpen ? <LazyLoadImage src={close} className='menu-btn' />
                                        :
                                        <LazyLoadImage src={menu} className='menu-btn' />
                                }
                            </span>
                        </button>
                    </div>
                    <div className={`navbar-link ${isMenuOpen ? 'open' : ''}`}>
                        <ul className="nav-ul">
                            <li>
                                <Link to="#about" className="nav-link" onClick={() => { scrollToSection('about'); toggleMenu(); }}>About</Link>
                            </li>
                            <li>
                                <Link to="#how-it-works" className="nav-link" onClick={() => { scrollToSection('how-it-works'); toggleMenu(); }}>How it works</Link>
                            </li>
                            <li>
                                <Link to="#benefits" className="nav-link" onClick={() => { scrollToSection('benefits'); toggleMenu(); }}>Benefits</Link>
                            </li>
                            <li>
                                <Link to="#download" className="nav-link" onClick={() => { scrollToSection('download'); toggleMenu(); }}>Download</Link>
                            </li>
                            <li>
                                <Link to="#faqs" className="nav-link" onClick={() => { scrollToSection('faq'); toggleMenu(); }}>FAQs</Link>
                            </li>

                            <li>
                                <Link to="#contact" className="nav-link" onClick={() => { scrollToSection('contact'); toggleMenu(); }}>Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="nav-ul">
                            <li className='mobile-hide tab-hide '>
                                <ul className="nav nav-pills topnav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <Link
                                            to="/"
                                            className={`nav-link ${activeTab === '/' ? 'active' : ''}`}
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected={activeTab === '/'}
                                            onClick={() => handleTabClick('/')}
                                        >
                                            Find Work
                                        </Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link
                                            to="/business"
                                            className={`nav-link ${activeTab === '/business' ? 'active' : ''}`}
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected={activeTab === '/business'}
                                            onClick={() => handleTabClick('/business')}
                                        >
                                            Hire Staff
                                        </Link>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default Navbar;
