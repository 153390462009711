import React, { Fragment } from 'react'
import FAQ from '../components/FAQ'
import Benefits from '../components/Benefits';
import hsbanner from "../assets/images/hs-banner.svg"
import aboutimg from "../assets/images/abtimg.svg"
import Contactus from '../components/Contactus';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import appbtn from "../assets/images/Appstorebtn.svg"
import googlebtn from "../assets/images/Googleplaybtn.svg"
import downloadimg from "../assets/images/bgdownloadimg.png"
import qrcode from "../assets/images/qrcode.svg"
import bgimg from "../assets/images/c-bg.svg"
import gcarddownload from "../assets/images/crewsor-cd.svg"
import bannerbg2 from "../assets/images/bannerbg2.svg"
import gm1 from "../assets/images/gm1.svg"
import gm2 from "../assets/images/gm2.svg"
import gm3 from "../assets/images/gm3.svg"
import gm4 from "../assets/images/gm4.svg"
import gm5 from "../assets/images/gm5.svg"
import gm6 from "../assets/images/gm6.svg"
import mobilebanimg from "../assets/images/mobilebanimg.svg"
import Meta from '../components/Meta';







// accordiondata

const accordionData = [
    {
        id: 1,
        title: 'How does Crewsor help me find skilled F&B staff?',
        content: "Crewsor connects your business with qualified F&B professionals who are ready to work. Whether you need a one-time shift or consistent coverage, you can easily find, review, and hire staff that meet your needs."
    },
    {
        id: 2,
        title: 'How quickly can I hire staff for a shift?',
        content: 'Crewsor’s platform is designed for fast hiring. Once you post a shift, qualified candidates can apply instantly, allowing you to review applications and hire someone quickly—sometimes within the same day.',
    },
    {
        id: 3,
        title: 'Can I hire staff for both part-time and full-time shifts?',
        content: 'Yes, Crewsor gives you the flexibility to post shifts for part-time, full-time, or even temporary positions. You can tailor your postings to fit your restaurant’s staffing needs.',
    },
    {
        id: 4,
        title: 'How do I know the candidates are qualified?',
        content: 'Each candidate’s profile includes their work experience, skills, and certifications. You can review these details, communicate directly with the applicants, and even interview them before confirming a hire.',
    },
    {
        id: 5,
        title: 'Is there a cost to using Crewsor to hire staff?',
        content: 'Yes, Crewsor operates on a subscription or pay-per-hire model. You only pay when you successfully hire staff, ensuring a cost-effective solution for your staffing needs.',
    },
    {
        id: 6,
        title: 'Can I schedule shifts in advance?',
        content: 'Absolutely. You can post shifts well in advance, allowing you to plan for busy periods or unexpected absences. This ensures that you always have access to staff when you need them.',
    },
    {
        id: 7,
        title: 'How do I manage my hires and shifts?',
        content: 'Crewsor’s platform allows you to easily track shifts, manage staff, and monitor payments all in one place. This makes it simple to keep your staffing organized and efficient.',
    },

];


// benefitsdata

const benefitsData = [
    {
        title: "Quick Access to Qualified Staff",
        description: "Crewsor connects F&B businesses with a pool of experienced professionals, allowing for quick hiring and shift coverage.",
        isBlack: false,
    },
    {
        title: "Flexible Staffing Solutions",
        description: " Whether you need staff for peak hours, special events, or regular shifts, Crewsor provides the flexibility to adjust staffing according to demand.",
        isBlack: true,
    },
    {
        title: "Streamlined Hiring Process",
        description: "The platform simplifies the hiring process, reducing time spent on interviews and paperwork. You can focus on running your business instead of managing staffing logistics.",
        isBlack: true,
    },
    {
        title: "Real-Time Updates",
        description: " Stay informed with real-time notifications about applications, confirmations, and any changes, ensuring smooth communication between businesses and staff.",
        isBlack: false,
    },




    {
        title: "Cost-Effective Staffing",
        description: " With Crewsor, you only pay for the shifts you fill, allowing you to manage costs more effectively without the burden of permanent hires.",
        isBlack: false,
    },
    {
        title: "Simplified Payment Processing",
        description: " Crewsor handles payment processing weekly, ensuring that your staff gets paid on time, which helps in retaining quality workers.",
        isBlack: true,
    },
    {
        title: "Enhanced Control Over Staffing",
        description: " Businesses can set specific criteria for staff, ensuring that the right individuals are matched to the right shifts based on skills and experience.",
        isBlack: true,
    },
    {
        title: "User-Friendly Platform",
        description: "The intuitive interface makes it easy for F&B businesses to navigate the app, post shifts, and manage staffing needs with minimal effort.",
        isBlack: false,
    },
    {
        title: "Strengthen Your Brand",
        description: "Quality staffing impacts a restaurent's brand. Crewsor connects business with skilled workers, enhancing service and boosting loyalty.",
        isBlack: false,
    },

    {
        title: "Robust Support System",
        description: "Crewsor offers support for both businesses and staff, ensuring that any issues or questions are addressed promptly for a smooth experience.",
        isBlack: false,
    },
];



const HireStaff = () => {
    return (
        <Fragment>
            <Meta title="Hire Staff For Your F&B Business Anytime | Crewsor"
                description="Quickly hire staff for your F&B business in Singapore. Flexible and reliable shift-based solutions are available anytime, anywhere. Download the Crewsor app today!"
                url="https://www.crewsor.com/business"
                ogImage="https://xdminds.s3.ap-south-1.amazonaws.com/crewquick.png"
                twitterImage="https://xdminds.s3.ap-south-1.amazonaws.com/crewquick.png"
            />


            <div className='find-work homepage'>
                <section className='banner-section'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-12 order-lg-2 order-md-1 order-1'>
                            <div className='bc-right '>
                                <LazyLoadImage src={hsbanner} alt='bannerimg' className='hs-banner-img img-fluid ' />
                                {/* <LazyLoadImage src={mobilebanimg} alt='banner' className='desktop-hide banner-img img-fluid' /> */}
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12 order-lg-1 order-md-2 order-2'>
                            <div className='bc-left'>
                                <h1 className='h1-text-1'>
                                    <span>Instant</span> staffing,<br />
                                    When you <span>need it</span><br />
                                </h1>
                                <p className='p-text-1'>
                                    Crewsor Makes hiring F&B staff simple and efficient, Book the right staff for your business anytime, anywhere, and keep your opertions running smoothly.
                                </p>
                                <div className='bc-btn-sec'>
                                    <div className='btn-ps'>
                                        <div className='btn-tool-div'>
                                            <button className='app-btn'>
                                                <LazyLoadImage src={appbtn} alt='appstore' />
                                            </button>
                                            <p className='p-cs-text'>Coming Soon</p>
                                        </div>
                                        <div className='btn-tool-div'>
                                            <button className='app-btn'>
                                                <LazyLoadImage src={googlebtn} alt='appstore' />
                                            </button>
                                            <p className='p-cs-text'>Coming Soon</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='about' id='about'>
                    <div className='about-content'>
                        <div className='t-head'>
                            <h2 className='h2-text-1'>The Future of Shift-Based Recruitment in F&B Industry</h2>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card about-card'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-9 col-12'>
                                            <div className='ac-left'>
                                                <h3 className='h3-text-1'>Who we are?</h3>
                                                <p className='p-text-3'>Crewsor is Singapore's premier staff recruiting  platform,
                                                    connecting F&B businesses with experienced
                                                    professionals. Whether you're looking to fill shifts or find
                                                    flexible work opportunities, Crewsor bridges the gap
                                                    between employers and staff across the industry.
                                                </p>
                                            </div>
                                            <LazyLoadImage src={aboutimg} className='about-img' alt='aboutimg' />

                                        </div>
                                        {/* <div className='col-lg-6 col-md-3 col-12'>
                                        <div className='ac-right'>
                                            <LazyLoadImage src={aboutimg} className='about-img' alt='aboutimg' />
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="grid-card" id='how-it-works'>
                    <div className='grid-card-section'>
                        <div className='t-head'>
                            <h2 className='h2-text-1'>Getting Started with Crewsor: A Staff Guide</h2>
                        </div>
                        <div className='row g-5 grid-card-row'>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='card g-card h-100'>
                                    <h3 className="g-card-h-text">Download the <br /> Crewsor App</h3>
                                    <div className='g-card-body'>
                                        <div className='g-card-left'>
                                            <p className='g-card-p'>Begin by downloading the <br className='break t-break' />
                                                Crewsor app from the <br className='break t-break' />
                                                Google Play Store or Apple <br className='break t-break' />
                                                App Store
                                            </p>
                                            <LazyLoadImage src={gcarddownload} alt='download' />
                                        </div>
                                        <div className='g-card-right '>
                                            <LazyLoadImage src={gm1} alt='grid-card' />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='card g-card h-100'>
                                    <h3 className="g-card-h-text">Sign<br /> Up</h3>
                                    <div className='g-card-body'>
                                        <div className='g-card-left'>
                                            <p className='g-card-p'>Create Your account by <br className='break t-break' />
                                                Signing up and providing <br className='break t-break' />
                                                the necessary information <br className='break t-break' />
                                                about your business and <br className='break t-break' />
                                                shifts requirements.
                                            </p>
                                            <LazyLoadImage src={gcarddownload} alt='download' />
                                        </div>
                                        <div className='g-card-right'>
                                            <LazyLoadImage src={gm2} alt='grid-card' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='card g-card h-100'>
                                    <h3 className="g-card-h-text">Post Your<br /> Shifts</h3>
                                    <div className='g-card-body'>
                                        <div className='g-card-left'>
                                            <p className='g-card-p'>Enter the shifts you need<br className='break t-break' />
                                                to be covered, specifying<br className='break t-break' />
                                                dates, time, and roles. Crewsor <br className='break t-break' />
                                                will automatically connect you <br className='break t-break' />
                                                with  qualified candidates.
                                            </p>
                                            <LazyLoadImage src={gcarddownload} alt='download' />
                                        </div>
                                        <div className='g-card-right'>
                                            <LazyLoadImage src={gm3} alt='grid-card' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='card g-card h-100'>
                                    <h3 className="g-card-h-text">Automatic<br /> Hiring</h3>
                                    <div className='g-card-body'>
                                        <div className='g-card-left'>
                                            <p className='g-card-p'>Based on the shifts you<br className='break t-break' />
                                                provide, Crewsor streamlines <br className='break t-break' />
                                                the hiring process, ensuring  <br className='break t-break' />
                                                the right  professionals are  <br className='break t-break' />
                                                matched  to your needs.
                                            </p>
                                            <LazyLoadImage src={gcarddownload} alt='download' />
                                        </div>
                                        <div className='g-card-right'>
                                            <LazyLoadImage src={gm4} alt='grid-card' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='card g-card h-100'>
                                    <h3 className="g-card-h-text">Weekly<br /> Payments</h3>
                                    <div className='g-card-body'>
                                        <div className='g-card-left'>
                                            <p className='g-card-p'>Enjoy hassle-free<br className='break t-break' />
                                                management, as Crewsor<br className='break t-break' />
                                                takes care of all payments   <br className='break t-break' />
                                                for your hired staff  <br className='break t-break' />
                                                weekly.
                                            </p>
                                            <LazyLoadImage src={gcarddownload} alt='download' />
                                        </div>
                                        <div className='g-card-right'>
                                            <LazyLoadImage src={gm5} alt='grid-card' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='card g-card h-100'>
                                    <h3 className="g-card-h-text">Get Paid<br /> Weekly</h3>
                                    <div className='g-card-body'>
                                        <div className='g-card-left'>
                                            <p className='g-card-p'>Enjoy the convenience of<br className='break t-break' />
                                                weekly payments. Crewsor <br className='break t-break' />
                                                ensures  that you receive your  <br className='break t-break' />
                                                earnings promptly for the  <br className='break t-break' />
                                                shifts you complete.
                                            </p>
                                            <LazyLoadImage src={gcarddownload} alt='download' />
                                        </div>
                                        <div className='g-card-right'>
                                            <LazyLoadImage src={gm6} alt='grid-card' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Benefits benefitsData={benefitsData} bhead={"Why F&B Businesses Choose Crewsor: A Benefit Analysis"} />
                <section className='d-crewsor' id='download'>
                    <div className='dc-content'>
                        <div className='t-head'>
                            <h2 className='h2-text-1'>Download the Crewsor App: <br /> Your F&B Staffing Solution</h2>
                        </div>
                        <div className='dc-center-t'>
                            <p className='p-text-7'>
                                Join Singapore's leading platform for F&B professionals. Download the Crewsor app to easily find shifts that match <br className='break t-break' />
                                your skills and availability. Get hired quickly, manage your schedule, and enjoy weekly payments-all at your fingertips!.
                            </p>
                        </div>
                        <div className='dc-card-section'>
                            <div className='row g-4'>
                                <div className='col-lg-7 col-md-7 col-12 dc-left '>
                                    <div className="download-bg">
                                        <img src={downloadimg} alt="image" className="img-fluid " />

                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-5 col-12 dc-right'>
                                    <div className='col-lg-12 col-md-12 col-12 '>
                                        <div className='card dc-r-card'>
                                            <div className='dcr-head'>
                                                <h2 className='h2-text-3'>Download the app </h2>
                                            </div>
                                            <div className='dc-r-card-body'>
                                                <div className='dc-btn-div'>
                                                    <button className='app-btn'>
                                                        <LazyLoadImage src={appbtn} alt='appstore' />
                                                        <span className="tooltip">Coming Soon</span>
                                                    </button>
                                                    <button className='app-btn'>
                                                        <LazyLoadImage src={googlebtn} alt='appstore' />
                                                        <span className="tooltip">Coming Soon</span>
                                                    </button>

                                                </div>
                                                <LazyLoadImage src={qrcode} alt='qrcode' className='qr-code-img' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LazyLoadImage src={bgimg} className='bg-img' alt='img' />
                </section>
                <FAQ accordionData={accordionData} />
                <Contactus />
            </div>
        </Fragment>
    )
}

export default HireStaff
