// src/components/Meta.js
import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description, url, siteName, ogImage, twitterImage }) => (
    <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:image" content={ogImage} />
        <meta name="twitter:image" content={twitterImage} />
        <link rel="canonical" href={url} />
    </Helmet>
);

Meta.defaultProps = {
    title: 'Find Flexible F&B Shifts That Fit Your Schedule | Crewsor',  // Default title
    description: 'Join Crewsor to discover flexible F&B shifts in Singapore. Work when it suits you, choose nearby venues, and get paid weekly. Download the Crewsor app today!',  // Default description
    url: 'https://www.crewsor.com/',
    siteName: 'Crewsor',
    ogImage: 'https://xdminds.s3.ap-south-1.amazonaws.com/crewfire.png',
    twitterImage: 'https://xdminds.s3.ap-south-1.amazonaws.com/crewfire.png'
};

export default Meta;
