import React from "react";

const Benefits = ({ benefitsData , bhead }) => {
    return (
        <div className='homepage' id='benefits'>
            <section className="benefits"  >
                <div className="benefits-content">
                    <div className="t-head">
                        <h2 className="h2-text-1">{bhead}</h2>
                    </div>
                    <div className="benefits-card-section">
                        <div className="row g-4">
                            {benefitsData.map((benefit, index) => (
                                <div className="col-lg-6 col-md-12 col-12" key={index}>
                                    <div className="b-card-bg h-100">
                                        <div
                                            className={`card  h-100  b-card ${benefit.isBlack ? "b-card-black" : "b-card-black"}`}
                                        >
                                            <div className="bc-card-content">
                                                <h2 className="h2-text-2">{benefit.title}</h2>
                                                <p className="p-text-5">{benefit.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Benefits
